export default function nudging() {
  let timeout;

  const debounce = (fn, ms, args) => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, ms, args);
  };

  return {
    editor: this.$refs.editor,
    timeout: null,

    init() {
      // We listen for custom event 'commentInput' on the editor to pass the tinyMCE content
      this.$refs.editor.addEventListener('commentInput', (e) => this.handleCommentInput(e));
    },

    handleCommentInput(e) {
      this.setContent(e);
      debounce(this.callAiFeedback, 1000, this);
    },

    setContent(e) {
      const content = e.detail;
      this.$refs.aiFeedbackContent.value = content;
    },

    callAiFeedback(context) {
      context.$refs.aiFeedbackSubmit.click();
    },
  }
}
